import React from 'react';
import PageSeo from '../components/PageSeo';
import Head from 'next/head';
import { getProductsByIds } from '../utils/typesense/product';
import { getPageBySlug } from '../utils/typesense/page';

import { ContentBlock } from '../components/content/ContentBlocks';
import { ITSProduct } from '../utils/typesense/types/product';
import { fetchInfoByName } from '../utils/typesense/siteInfo';
import { find, isEmpty } from 'lodash';
import dynamic from 'next/dynamic';

const ContentBlocks = dynamic(
  () => import('../components/content/ContentBlocks'),
  {
    loading: () => <p>Loading...</p>,
  },
);

export default function HomePage({ fullHead, contents }) {
  return (
    <>
      <Head>
        <meta
          name="google-site-verification"
          content="M2FlCIQ3xu9vhoL9xsISPc9JXIwNjIsKV_3BZw2fH0U"
        />
      </Head>
      <PageSeo seoFullHead={fullHead} />

      <ContentBlocks contents={contents} />
    </>
  );
}

export async function getStaticProps(context) {
  const pageData = await getPageBySlug('home');
  const tsHomeData = await fetchInfoByName('homepage_layout');
  const homePageData: ContentBlock[] = JSON.parse(
    !isEmpty(tsHomeData) ? tsHomeData.value : '',
  );

  let popularHampers: ITSProduct[] = [];
  let productIds = '';
  const featuredProductsConfig = find(homePageData, ['blockId', 'products']);
  if (featuredProductsConfig) {
    productIds = featuredProductsConfig.metaData['AU'].productId;
    productIds = productIds.replace(/ /g, '');
    popularHampers = await getProductsByIds(
      productIds.split(',').map((id) => parseInt(id)),
    );
  }

  let contents: any = [];

  if (!isEmpty(homePageData)) {
    homePageData.map((block, index) => {
      if (block.blockId === 'products') {
        const md = {
          metaData: {
            AU: {
              products: popularHampers,
            },
          },
        };
        contents.push({
          ...block,
          ...md,
        });
      } else {
        contents.push(block);
      }
    });
  }

  return {
    props: {
      fullHead: pageData?.seoFullHead,
      contents: contents,
    },
    revalidate: 43200, // Refresh the generated page every 12 hours,
  };
}
