import Head from 'next/head';
import React from 'react';
import parse from 'html-react-parser';
import { seoUrlParser } from '../utils/helper';
import { decode } from 'html-entities';

type Props = {
    seoFullHead: string;
};

const PageSeo = ({ seoFullHead }: Props) => {
    return <Head>{parse(seoUrlParser(decode(seoFullHead)))}</Head>;
};

export default PageSeo;
